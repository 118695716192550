import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { QuoteContext } from "../../Context/QuoteContext"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { QuoteContainer } from "../bilforsakring/lagg-till-mobilnummer"
import Icon from "../../components/Icon/Icon"
import { useTheme } from "emotion-theming"
import FormLoader from "../../components/GlobalAssets/FormLoader"
import ButtonLoader from "../../components/GlobalAssets/ButtonLoader"
import { formResetError, fetchingFailed } from "../../Context/Actions"
import { DispatchRequest } from "../../API/QuoteService"
import { ENDPOINT } from "../../API/endpoints"
import { isNullOrWhitespace } from "../../utils/HelperFunctions"

const ChangePaymentPage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const [selectedOption, setSelectedOption] = useState({
    selectedId: 0,
    selected: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    dispatch(formResetError())
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!state.hasQuote && !state.hasLoadedQuote) {
    if (typeof window === "undefined") {
      return null
    }

    // Handle refresh/history-back
    if (isNullOrWhitespace(location.search)) {
      navigate("/")
      return null
    }

    navigate(`/s?${new URLSearchParams(location.search).toString()}`)

    return null
  }

  const getSelectedPayment = paymentId => {
    switch (paymentId) {
      case 1:
        return "NEW_CARD"

      case 2:
        return "NEW_AG"

      default:
        return ""
    }
  }

  const handleCheckout = e => {
    e.preventDefault()
    setIsLoading(true)

    if (isLoading) return

    dispatch(formResetError())
    ;(async () => {
      DispatchRequest(ENDPOINT.SELECT_PAYMENT, {
        inputData: {
          guid: state.guid,
          paymentType: getSelectedPayment(selectedOption.selectedId),
        },
      }).then(response => {
        if (response.status === 200) {
          setIsLoading(false)
          if (response.data.status.code === 0) {
            // All is good

            if (typeof window !== "undefined") {
              setIsLoading(false)
              setSelectedOption({
                selectedId: 0,
                selected: false,
              })

              navigate(response.data.result.redirectUrl)
            }
          } else {
            // Something is wrong

            setSelectedOption({
              selectedId: 0,
              selected: false,
            })

            dispatch(
              fetchingFailed(
                response.data.status.message ||
                  "Vi har tekniska problem, prova igen om en stund."
              )
            )
          }
        } else {
          setSelectedOption({
            selectedId: 0,
            selected: false,
          })

          setIsLoading(false)

          // Something is very wrong
          dispatch(
            fetchingFailed(response.data || "Något gick fel, prova igen.")
          )
        }
      })
    })()
  }

  return (
    <Layout>
      <SEO
        title="evoli - Ändra betalsätt."
        noIndex={true}
        referrer="no-referrer"
      />

      <QuoteContainer>
        {state.isLoading && <FormLoader />}
        <QuoteHeroContent>
          <Icon
            name="card"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />

          {state.quote.result.productName === "evoli" ? (
            <>
              <h1>Ändra betalsätt för din bilförsäkring!</h1>

              <p>
                Klicka på en av knapparna nedan för att ändra betalsätt för din{" "}
                <LowerCase>{state.quote.result.coverLevelLong}</LowerCase>{" "}
                gällande bil{" "}
                <UpperCase>{state.quote.result.registrationNumber}</UpperCase>.
              </p>
            </>
          ) : (
            <>
              <h1>Ändra betalsätt för din försäkring!</h1>

              <p>
                Klicka på en av knapparna nedan för att ändra betalsätt för din
                försäkring.
              </p>
            </>
          )}
        </QuoteHeroContent>

        <PaymentForm onSubmit={handleCheckout}>
          <ActionWrapper>
            <PayButton
              type="submit"
              onClick={() =>
                setSelectedOption({
                  selectedId: 1,
                  selected: true,
                })
              }
              disabled={
                selectedOption.selectedId !== 0 &&
                selectedOption.selectedId !== 1
              }
              isSelected={selectedOption.selectedId === 1}
            >
              <CheckoutButton>
                <div>
                  <p>Månadsbetalning (kort)</p>
                  <b>Betala med Visa/Mastercard</b>
                </div>
                <span>
                  {selectedOption.selectedId === 1 && <ButtonLoader />}
                </span>
              </CheckoutButton>
            </PayButton>

            <PayButton
              type="submit"
              onClick={() =>
                setSelectedOption({
                  selectedId: 2,
                  selected: true,
                })
              }
              disabled={
                selectedOption.selectedId !== 0 &&
                selectedOption.selectedId !== 2
              }
              isSelected={selectedOption.selectedId === 2}
            >
              <CheckoutButton>
                <div>
                  <p>Månadsbetalning (bankkonto)</p>
                  <b>Betala med Autogiro</b>
                </div>
                <span>
                  {selectedOption.selectedId === 2 && <ButtonLoader />}
                </span>
              </CheckoutButton>
            </PayButton>

            {state.error && <FormError>{state.error}</FormError>}
          </ActionWrapper>
        </PaymentForm>
      </QuoteContainer>
    </Layout>
  )
}

export default ChangePaymentPage

const FormError = styled.div`
  color: #ff4984;
  margin: 0;

  margin-top: 20px;
  font-size: 12px;
  text-align: center;
`

const PaymentForm = styled.form`
  width: 100%;
  max-width: 343px;
  margin: 0;
`

const PayButton = styled.button`
  border: 0;
  padding: 0;
  display: flex;
  margin-bottom: 14px;
  width: 100%;

  :not(:first-of-type) {
    margin-bottom: 0;
  }

  :hover {
    :disabled {
      cursor: not-allowed;
    }
    cursor: ${props => (props.isSelected ? "wait" : "pointer")};
  }
`

const UpperCase = styled.span`
  text-transform: uppercase;
`
const LowerCase = styled.span`
  text-transform: lowercase;
`

const CheckoutButton = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 18px 4px rgba(95, 56, 218, 0.2);
  background-image: linear-gradient(to bottom, #6739e0, #5737d5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: canter;

  :not(:first-of-type) {
    margin-top: 14px;
  }

  p {
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffe428;
    margin: 0;
  }
  b {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    margin-top: 6px;
  }

  span {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffe428;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 343px;
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 18px;

  @media (max-width: 374px) {
    margin-top: 0px;
  }

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    max-width: 220px;

    span {
      display: block;
      color: #5558ea;
      margin-top: 5px;
    }
  }

  p {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;
    max-width: 310px;
  }

  span {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    @media (max-width: 374px) {
      max-width: 170px;
    }
  }
`
